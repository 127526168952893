<template>
    <div v-if="profile !== null" class="app-navbar-profile-content">  
        <b-navbar sticky class="app-navbar-profile">
            <div class="app-navbar-header">
                <b-row>
                    <b-col cols="8">

                  </b-col>
                  <b-col end class="app-navbar-profile-margin-30">
                    <div class="d-flex justify-content-end align-items-center">
                      <div class="font-weight-800 mr-4 mt-1"> {{trans('cao-cao-profile', 271)}} </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-3">
                    <b-col> 
                    <settings-section-profile-status-bar :profile.sync ="profile" />
                    </b-col>        
                </b-row> 
            </div>
        </b-navbar>
        <div>
            <b-row>
                <b-col md="12" lg="8" class="pr-lg-2">
                    <b-row class="mb-3" v-if="profile.cao_schedule && profile.cao_schedule.status == 1">
                        <b-col > 
                            <settings-section-profile-action-points-component :profile.sync ="profile" />
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col > 
                            <settings-section-profile-cao-schedule-component :profile.sync ="profile" />
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col > 
                            <settings-section-profile-wage-component :profile.sync ="profile" />
                        </b-col>
                    </b-row>
                </b-col>

                <b-col md="12" lg="4" class="mt-3 mt-lg-0 pl-lg-2">
                    <settings-section-profile-functions-component :profile.sync ="profile" />
                </b-col>
            
            </b-row>
        </div>
    </div>
</template>

<script>
import axios from "@axios";
import SettingsSectionProfileStatusBar from './StatusBar/SettingsSectionProfileStatusBar.vue';
import SettingsSectionProfileFunctionsComponent from './Functions/SettingsSectionProfileFunctionsComponent.vue';
import SettingsSectionProfileCaoScheduleComponent from './CaoSchedule/SettingsSectionProfileCaoScheduleComponent.vue';
import SettingsSectionProfileWageComponent from './Wage/SettingsSectionProfileWageComponent.vue';
import SettingsSectionProfileActionPointsComponent from './ActionPoints/SettingsSectionProfileActionPointsComponent.vue';

export default {
    
    components: {
        SettingsSectionProfileStatusBar,
        SettingsSectionProfileFunctionsComponent,
        SettingsSectionProfileCaoScheduleComponent,
        SettingsSectionProfileWageComponent,
        SettingsSectionProfileActionPointsComponent
       
    },

    created() {
        this.getData();
    },

    watch: {
      "profile.refresh": {
            handler: function(val) {
                if (val > 0) {
                    this.getSectionData();
                }                
            },
        },    
    },

    data() {
        return {
            profile: null,
            id_cao_data_main: this.$route.params.id_cao_data_main,
        };
    },

    methods: {
        getData(){
            axios
                .get("settings/sections/getProfile/" + this.id_cao_data_main)
                .then((res) => {
                    this.profile = res.data;  
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                });
            },

         getSectionData(){
            axios
                .get("settings/sections/getSectionData/" + this.id_cao_data_main)
                .then((res) => {
                    if(res.data.length > 0){
                    this.profile.section_data = res.data[0];  
                    } 
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                });
            },
    },
};
</script>

<style scoped></style>
