<template>
    <b-card>

        <b-row class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right v-if="profile.cao_schedule != null">
                <template #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change()">{{trans('edit-percentages',207)}} </b-dropdown-item>
                <b-dropdown-item @click="changeTravel()">{{trans('cw-edit-travel-costs',192)}} </b-dropdown-item>                                        
            </b-dropdown>
        </b-row>

        <b-row class="app-card-header">
            <b-col cols="12">
                <div class="app-card-profile-title">
                    {{trans('settings-ort-schedule',167)}} 
                    <!-- <span v-if="(profile.cao_schedule != null)">( van {{profile.cao_schedule.date_from}} <span v-if="profile.cao_schedule != null && profile.cao_schedule.date_to">tot {{profile.cao_schedule.date_to}} </span> )</span>  -->
                </div>
            </b-col>
        </b-row>
        <b-row v-if="profile.cao_schedule != null">
            <b-col cols="12" class="col-xs-12 col-sm-6 col-lg-4 col-xl-13">
                <div v-for="schedule, index in JSON.parse(profile.cao_schedule.schedule)" :key="index">
                    <b-row class="app-card-header" v-if="index == 1">
                        <b-col cols="12">
                            <div class="col-list-label mt-2"><b>{{days[index-1]}}</b></div>
                        </b-col>
                    </b-row>

                    <b-row v-for="data, index2 in schedule" :key="index2" class="app-card-header" >
                        <b-col cols="12" start v-if="index == 1">
                            <div class="d-flex flex-row col-list-label">
                                <span class="mr-auto">{{data.time_from + ' - ' + data.time_to}}</span>
                                <span class="mr-auto">{{data.percent_value + '%'}}</span>
                            </div>
                        
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col cols="12" class="col-xs-12 col-sm-6 col-lg-4 col-xl-13">
                <div v-for="schedule, index in JSON.parse(profile.cao_schedule.schedule)" :key="index">
                    <b-row class="app-card-header" v-if="index == 2">
                        <b-col cols="12">
                            <div class="col-list-label mt-2"><b>{{days[index-1]}}</b></div>
                        </b-col>
                    </b-row>

                    <b-row v-for="data, index2 in schedule" :key="index2" class="app-card-header" >
                        <b-col start  v-if="index == 2" cols="12">
                            <div class="d-flex flex-row col-list-label">
                                <span class="mr-auto">{{data.time_from + ' - ' + data.time_to}}</span>
                                <span class="mr-auto">{{data.percent_value + '%'}}</span>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col cols="12" class="col-xs-12 col-sm-6 col-lg-4 col-xl-13">
                <div v-for="schedule, index in JSON.parse(profile.cao_schedule.schedule)" :key="index">
                    <b-row class="app-card-header" v-if="index == 3">
                        <b-col cols="12">
                            <div class="col-list-label mt-2"><b>{{days[index-1]}}</b></div>
                        </b-col>
                    </b-row>

                    <b-row v-for="data, index2 in schedule" :key="index2" class="app-card-header" >
                        <b-col start  v-if="index == 3" cols="12">
                            <div class="d-flex flex-row col-list-label">
                                <span class="mr-auto">{{data.time_from + ' - ' + data.time_to}}</span>
                                <span class="mr-auto">{{data.percent_value + '%'}}</span>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col cols="12" class="col-xs-12 col-sm-6 col-lg-4 col-xl-13">
                <div v-for="schedule, index in JSON.parse(profile.cao_schedule.schedule)" :key="index">
                    <b-row class="app-card-header" v-if="index == 4">
                        <b-col cols="12">
                            <div class="col-list-label mt-2"><b>{{days[index-1]}}</b></div>
                        </b-col>
                    </b-row>

                    <b-row v-for="data, index2 in schedule" :key="index2" class="app-card-header" >
                        <b-col start  v-if="index == 4" cols="12">
                            <div class="d-flex flex-row col-list-label">
                                <span class="mr-auto">{{data.time_from + ' - ' + data.time_to}}</span>
                                <span class="mr-auto">{{data.percent_value + '%'}}</span>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col cols="12" class="col-xs-12 col-sm-6 col-lg-4 col-xl-13">
                <div v-for="schedule, index in JSON.parse(profile.cao_schedule.schedule)" :key="index">
                    <b-row class="app-card-header" v-if="index == 5">
                        <b-col cols="12">
                            <div class="col-list-label mt-2"><b>{{days[index-1]}}</b></div>
                        </b-col>
                    </b-row>

                    <b-row v-for="data, index2 in schedule" :key="index2" class="app-card-header" >
                        <b-col start  v-if="index == 5" cols="12">
                            <div class="d-flex flex-row col-list-label">
                                <span class="mr-auto">{{data.time_from + ' - ' + data.time_to}}</span>
                                <span class="mr-auto">{{data.percent_value + '%'}}</span>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
        <app-divider-modal v-if="profile.cao_schedule != null" :isLine="true"/>
        <b-row v-if="profile.cao_schedule != null">
            <b-col cols="12" class="col-xs-12 col-sm-6 col-lg-4 col-xl-13">
                <div v-for="schedule, index in JSON.parse(profile.cao_schedule.schedule)" :key="index">
                    <b-row class="app-card-header" v-if="index == 6">
                        <b-col cols="12">
                            <div class="col-list-label mt-2"><b>{{days[index-1]}}</b></div>
                        </b-col>
                    </b-row>

                    <b-row v-for="data, index2 in schedule" :key="index2" class="app-card-header" >
                        <b-col cols="12" start v-if="index == 6">
                            <div class="d-flex flex-row col-list-label">
                                <span class="mr-auto">{{data.time_from + ' - ' + data.time_to}}</span>
                                <span class="mr-auto">{{data.percent_value + '%'}}</span>
                            </div>
                        
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col cols="12" class="col-xs-12 col-sm-6 col-lg-4 col-xl-13">
                <div v-for="schedule, index in JSON.parse(profile.cao_schedule.schedule)" :key="index">
                    <b-row class="app-card-header" v-if="index == 7">
                        <b-col cols="12">
                            <div class="col-list-label mt-2"><b>{{days[index-1]}}</b></div>
                        </b-col>
                    </b-row>

                    <b-row v-for="data, index2 in schedule" :key="index2" class="app-card-header" >
                        <b-col start  v-if="index == 7" cols="12">
                            <div class="d-flex flex-row col-list-label">
                                <span class="mr-auto">{{data.time_from + ' - ' + data.time_to}}</span>
                                <span class="mr-auto">{{data.percent_value + '%'}}</span>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
        <app-divider-modal v-if="profile.cao_schedule != null" :isLine="true"/>
        <b-row class="app-card-header">
                <b-col>
                     <div class="col-list-label mt-1"><b>{{trans('cp-ort-caps',190)}}</b></div>
                </b-col>
        </b-row>
        <b-row>
            <b-col start>
                <p class="col-list-label">{{trans('settings-ort-max-rate', 167)}}</p>
                <p class="col-list-label">{{trans('settings-min-scale', 167)}}</p>
            </b-col>
            <b-col>
                <p class="col-form-value">{{profile.cao_schedule ? profile.cao_schedule.ort_max_rate : '-'}}</p>
                <p class="col-form-value">{{profile.cao_schedule ? profile.cao_schedule.ort_minimum_scale : '-'}}</p>
            </b-col>
        </b-row>
        <app-divider-modal v-if="profile.cao_schedule != null" :isLine="true"/>
        <b-row class="app-card-header">
                <b-col>
                     <div class="col-list-label mt-1"><b>{{trans('travel-costs',192)}}</b></div>
                </b-col>
        </b-row>
        <b-row>
            <b-col start>
                <p class="col-list-label">{{trans('cw-home-work',194)}}</p>
                <p class="col-list-label">{{trans('cw-work-work',194)}}</p>
            </b-col>
            <b-col>
                <p class="col-form-value">{{profile.cao_schedule.work_home ? profile.cao_schedule.work_home : '-'}}</p>
                <p class="col-form-value">{{profile.cao_schedule.work_work ? profile.cao_schedule.work_work : '-'}}</p>
            </b-col>
        </b-row>

        <settings-section-profile-cao-schedule-change v-if="mChange.show" :show.sync="mChange.show" :args.sync="mChange.args" :result.sync="mChange.result" :return.sync="profile.cao_schedule" />
        <settings-section-profile-cao-schedule-change-travel v-if="mChangeTravel.show" :show.sync="mChangeTravel.show" :args.sync="mChangeTravel.args" :result.sync="mChangeTravel.result" :return.sync="profile.cao_schedule" />
    </b-card> 
</template>
<script>

import SettingsSectionProfileCaoScheduleChange from './SettingsSectionProfileCaoScheduleChange.vue';
import SettingsSectionProfileCaoScheduleChangeTravel from './SettingsSectionProfileCaoScheduleChangeTravel.vue';

export default {

    components: {
        SettingsSectionProfileCaoScheduleChange,
        SettingsSectionProfileCaoScheduleChangeTravel
    },
    
    props: ["profile"],

    data(){ 
        return {
            days:[this.trans('monday',182), this.trans('tuesday',182), this.trans('wednesday',182), this.trans('thursday',182), this.trans('friday',182), this.trans('saturday',182), this.trans('sunday',182)],
            mChange: {
                show: false,
                args: { 
                    id_cao_data_schedule: null,
                    percents: null
                }, result: 0, return: null,
            },
            mChangeTravel: {
                show: false,
                args: { 
                    id_cao_data_schedule: null,
                    work_home: null,
                    work_work: null
                }, result: 0, return: null,
            }
        }
    },

    methods: {
        change(){
            this.mChange.args.id_cao_data_schedule = this.profile.cao_schedule.id;
            this.mChange.args.percents = this.profile.cao_schedule.percents ? JSON.parse(this.profile.cao_schedule.percents) : ['']

            this.mChange.show = true;
        },

        changeTravel(){
            this.mChangeTravel.args.id_cao_data_schedule = this.profile.cao_schedule.id;
            this.mChangeTravel.args.work_home = this.profile.cao_schedule.work_home;
            this.mChangeTravel.args.work_work = this.profile.cao_schedule.work_work;

            this.mChangeTravel.show = true;
        }
    },
}
</script>
<style>
@media (min-width: 1300px){
    .col-xl-13 {
        max-width:20% !important;
        min-width: 20% !important;
    }
}

</style>