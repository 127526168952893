<template>
    <b-modal ref="modal-edit" v-model="computedShow" no-close-on-backdrop :title="trans('cw-edit-travel-costs',192)"> 
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="">
                <app-row-left-label :label="trans('cw-home-work',194)">
                    <app-input v-model="cArgs.work_home" type="decimal_01"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('cw-work-work',194)">
                    <app-input v-model="cArgs.work_work" type="decimal_01"/>
                </app-row-left-label>
            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
       change() {
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{

                    this.loading = true;
                    axios
                        .post("settings/sections/profile/schedule/changeTravelCosts", {
                            id_cao_data_schedule: this.cArgs.id_cao_data_schedule,
                            work_home: this.cArgs.work_home,
                            work_work: this.cArgs.work_work
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            this.cReturn =  res.data;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading = false;
                        });
                    }
            })
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped>
.app-local-input{
    width: 280px !important;
}
.app-local-button{
    opacity: 1 !important;
    border: solid 1px #D0D5DD !important;
    border-radius: 0.25rem !important;
    line-height: 1rem !important;
    font-size:  1rem !important;
    color: #066791 !important;
    max-height: 31.3px !important;
    min-height: 31.3px !important;
}
.app-local-button:hover{
    border-color: #066791 !important;
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1);
}

.app-local-button:focus{
    border-color: #066791 !important;
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1);
}

.app-local-button-disabled:hover, .app-local-button-disabled:focus{
    border: solid 1px #D0D5DD !important;
    box-shadow: none !important;
}
</style>
