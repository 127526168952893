<template>
    <div>
        <b-card v-if="profile.cao_schedule && profile.cao_schedule.status == 1">
            <b-row class="app-card-header">
                <b-col start>
                    <b-alert variant="warning" show class="mb-0">
                        <div class="d-flex w-100 justify-content-between">
                            <div>
                                <div class="d-flex w-100 align-items-center">
                                    <b-icon icon="person-lines-fill" font-scale="4" class="mr-4"></b-icon>                                  
                                    <div class=" ml-3 w-100 align-items-center">           
                                        <span class="align-middle font-weight-600 text-base">{{trans('concept-schedule',207)}}</span>  <br/>  
                                        <span class="text-small">{{trans('click-to-confirm-schedule',207)}}</span>                                   
                                    </div>                                 
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <app-button type="primary" @click="acceptSchedule()" :loading="loading">{{trans('confirm-data',175)}}</app-button>                              
                            </div>
                        </div>
                    </b-alert> 
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "@axios";

export default {
    components: {        

    },

    props:["profile"],

    created(){
    },
    
    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }
        } 
    },

    data() {
        return {
           loading: false
        }
    },

    methods: {
        acceptSchedule(){
            this.loading = true;
            axios
                .post("settings/sections/profile/action-points/acceptSchedule", {
                    id_cao_data_schedule: this.profile.cao_schedule.id,
                    id_cao_data_main: this.profile.id
                })
                .then((res) => {
                    this.cProfile.cao_schedule = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading = false;
                });
        }

    }
};
</script>

<style scoped>
</style>