<template>
    <b-card>
        <app-view-table :isTopBar="false" :loading="loading_list" :filters.sync="filters" :items="cProfile.wage.items" :fields="fields" :isDropdownMenu="false">
            
        </app-view-table>

    </b-card>  
</template>
<script>

import axios from "@axios";

export default {

    props: ['profile'],
    
    mounted() {
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', this.profile) }
        }
    },

    data() {
        return {
            loading_list: false,
            filters: {
                page: 1,
                per_page: 10,
                 sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0,

                },
                
                filtering: {},
                search: "",
                status: 1,
                changed: 0,
            },
            fields: [
                { key: "date_from", label: this.trans('date-from',182), thStyle: { "min-width": "80px", width:"100%" }, visible: true, filtering: false },
                { key: "date_to", label: this.trans('date-to',182), thStyle: { "min-width": "200px", "max-width": "250px" }, visible: true, filtering: false },
                { key: "scale", label: this.trans('scale',182), thStyle: { "min-width": "200px", "max-width": "250px" }, visible: true, filtering: false }, 
                { key: "level", label: 'Level', thStyle: { "min-width": "200px", "max-width": "250px" }, visible: true, filtering: false },
                { key: "rate", label: 'Tarief', thStyle: { "min-width": "200px", "max-width": "250px" }, visible: true, filtering: false },                        
            ],
            

            items: [],
        };
    },


    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("settings/sections/profile/wage/getList", {
                    filters: JSON.stringify(this.filters),
                    id_cao_data_main: this.profile.id,
                })
                .then((res) => {
                    this.cProfile.wage.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        onCreateThis() { 


            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('profile.wage.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

        }
    },
 
};
</script>
<style>
</style>