<template>
    <div>
        <b-row class="align-items-center ml-1 mr-1">
            <b-col cols="8">
                <b-row>
                    <b-col>
                        <div style="font-size: 30px;">{{this.profile.section_data.name}}</div>
                        <div style="font-size: 16px; ">{{this.profile.section_data.short_name}}</div>
                    </b-col>
                    <b-col cols="auto" end>
                        <div style="font-size: 14px;  font-weight: 600; text-align:left" class="elements-inline">
                        <app-select v-model="cProfile.current_period" reduceValue :clearable="false" :options="cProfile.cao_periods" class="mt-4 app-width-min-220" :customLoading="loading" :disable="cProfile.cao_periods.length <= 1" @input="changeSchedule"/>
                        <b-button variant="transparent" class="list-button app-input-button-group app-icon-button my-auto ml-3" @click="addORT" :disabled="loading || profile.cao_schedule.status == 1 || profile.current_period != profile.cao_periods[0].value">
                            <b-icon icon="plus" class="list-button-icon"></b-icon>
                        </b-button>
                    </div>
                    </b-col>
                </b-row>
            </b-col>

        
        </b-row>
    </div>
</template>
<script>
import axios from "@axios";

export default {
    components: {
    },

    props:["profile"],

    computed: {
        cProfile:{
            get() { return this.profile },
            set(value) { this.$emit("update:profile", value) }
        }
    },

    data(){
        return{
            loading: false,
        }
    },

    methods:{

        changeSchedule(id_cao_data_schedule){
            this.loading = true;

            axios
                .post('settings/sections/profile/schedule/getSchedule', {
                    id_cao_data_schedule: id_cao_data_schedule
                })
                .then((res) => {
                    this.cProfile.cao_schedule = res.data;
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                })
        },

        addORT(){
            this.$router.push({ name: "sections-profile-schedule-add", params: { id_cao_data_schedule: this.profile.cao_schedule.id} });
        }
    }
};
</script>

<style scoped>
.dotActive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #20dc37;
  border-radius: 50%;
  display: inline-block;
}
.dotInactive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.elements-inline {
    display: flex;
}

.list-button {
    border:1px solid #ced4da; 
    margin-left:8px; 
    border-radius: 4px; 
    padding: 0px; 
    min-width:31px; 
    max-height:31px;
    margin-top: 24px !important;
}

.list-button:hover {
    border-color: #0D7BB3;
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1) ;
}
.list-button:focus-visible{
    background: #dcf0f9 !important;
    border-color: #0D7BB3 !important;
    box-shadow: 0px 0px 5px -2px rgb(13 126 179) !important;
}
.list-button-icon {
    min-width:29px; 
    min-height:29px;
}

.list-button.disabled:hover, .list-button:disabled {
    border-color: #ced4da;
    box-shadow: none ;
    background: transparent !important;
}
</style>
