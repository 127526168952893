<template>
    <b-modal ref="modal-edit" v-model="computedShow" no-close-on-backdrop :title="trans('change',175)">
         <ValidationObserver ref="form" >

             <app-row-left-label :label="trans('level',207)" >
                 <app-input v-model="cArgs.level" :validatorName="trans('level',207)" type="integer" validatorRules="required"/>
             </app-row-left-label>

             <app-row-left-label :label="trans('settings-btw-adjustable-in-placement',207)">
                 <app-radio-group v-model="cArgs.editable_btw" :options="options_editable" validatorName="BTW setting aanpasbaar" validatorRules="required"/>
             </app-row-left-label>

             <app-row-left-label :label="trans('default-vat-value',182)">
                <app-radio-group v-model="cArgs.default_btw_value" :options="options_default_value" validatorName="Default BTW waarde" validatorRules="required"/>
             </app-row-left-label>
        </ValidationObserver>

        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return", "filters"],
    
    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            loading: false,

            options_editable: [
                                {value: 0, text: this.trans('no-option',3)},
                                {value: 1, text: this.trans('yes-option',3)}
                              ],
            options_default_value: [
                                    {value: 0, text: this.trans('settings-no-vat',206)},
                                    {value: 1, text: this.trans('settings-with-vat',206)}
                                   ]
        };
    },

    methods: {
        change() {
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("settings/sections/profile/functions/change", {
                            id: this.cArgs.id,
                            level: this.cArgs.level,
                            id_cao_data_main: this.cArgs.id_cao_data_main,
                            default_btw_value: this.cArgs.default_btw_value,
                            editable_btw: this.cArgs.editable_btw,
                            filters: JSON.stringify(this.cArgs.filters),
                        })
                       .then((res) => {
                            this.cReturn.functions = res.data;
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                        })
                        .catch((error) => {

                            console.error(`error during request: ${error}`);
                        });
                        }
            })
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped></style>