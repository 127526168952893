<template>
    <b-modal ref="modal-edit" v-model="computedShow" no-close-on-backdrop :title="trans('edit-percentages',207)"> 
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="">
                <app-row-left-label :label="trans('cw-percentage',194)">
                    <b-row v-for="item, index in cArgs.percents" :key="index" class="mb-1">
                        <app-input v-model="cArgs.percents[index]" class="app-local-input" type="integer_max_100" :currentError="firstLoad == false && cValidateValue == null" :key="refresh"/>
                        <app-button type="secondary-grey" class="ml-1 app-local-button p-2" :class="{ 'app-local-button-disabled':index == 0 && cArgs.percents.length == 1 }" @click="remove(index)" :disabled="index == 0 && cArgs.percents.length == 1">
                            <b-icon icon="dash" class="list-button-icon"></b-icon>
                        </app-button>
                        <validation-provider ref="provider" v-slot="{ errors}" rules="required" :name="trans('cw-percentage',194)">
                            <input type="hidden" v-model="cValidateValue" />
                            <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error" v-overflow-tooltip>{{ errors[0] }}</small> 
                        </validation-provider>
                    </b-row>
                </app-row-left-label>
                <b-row>
              
                <b-col end class='d-flex justify-content-end pl-0' >
                    <app-button type="cancel" @click="add" class="p-0 app-local-text"><b-icon icon="plus-circle" class="mr-1" /> <span> {{trans('add-percentages',207)}} </span></app-button>
                </b-col>
            </b-row>
            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
        cValidateValue: {
            get(){
                var result = 0;
                this.cArgs.percents.forEach(element => {
                    if(element != ''){
                        result++;
                    }
                });
                return result ? true : null;
            }
        },
    },

    data() {
        return {
            loading: false,
            percentages:[''],
            firstLoad: true,
            refresh:0
        };
    },

    methods: {
       change() {
            this.firstLoad = false;
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                
                    for(let i=0;i<this.cArgs.percents.length;i++){
                        if(this.cArgs.percents[i] == null || this.cArgs.percents[i] == ''){
                            this.cArgs.percents.splice(i, 1);
                            i--;
                        }else{
                            this.cArgs.percents[i] = parseInt(this.cArgs.percents[i])
                        }
                    }
                    this.cArgs.percents.sort(function(a, b) {
                        return a - b;
                    });

                    this.loading = true;
                    axios
                        .post("settings/sections/profile/schedule/addPercentage", {
                            id_cao_data_schedule: this.cArgs.id_cao_data_schedule,
                            percents: JSON.stringify(this.cArgs.percents)
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            this.cReturn =  res.data;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading = false;
                        });
                    }
            })
        },

        add(){
            this.cArgs.percents.push('');
            this.refresh++;
        },

        remove(index){
            this.cArgs.percents.splice(index, 1);
            this.refresh++;
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped>
.app-local-input{
    width: 280px !important;
}
.app-local-button{
    opacity: 1 !important;
    border: solid 1px #D0D5DD !important;
    border-radius: 0.25rem !important;
    line-height: 1rem !important;
    font-size:  1rem !important;
    color: #066791 !important;
    max-height: 31.3px !important;
    min-height: 31.3px !important;
}
.app-local-button:hover{
    border-color: #066791 !important;
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1);
}

.app-local-button:focus{
    border-color: #066791 !important;
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1);
}

.app-local-button-disabled:hover, .app-local-button-disabled:focus{
    border: solid 1px #D0D5DD !important;
    box-shadow: none !important;
}
</style>
