<template>
    <div>
        <b-card>
            <app-view-table :title="trans('settings-functions',182)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="cProfile.functions.items" :fields="fields" :isSearch="true" bottomBarStaticHeight>
                
                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="change(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>
                </template>

            </app-view-table>

        </b-card>  
        <settings-section-profile-function-change v-if="mChange.show" :show.sync="mChange.show" :result.sync="mChange.result" :args="mChange.args" :filters.sync="filters" :return.sync="cProfile"/>
    </div>
</template>
<script>

import axios from "@axios";
import SettingsSectionProfileFunctionChange from "./SettingsSectionProfileFunctionChange.vue";

export default {

    components: {
        SettingsSectionProfileFunctionChange,
    },

    props: ['profile'],
    
    mounted() {
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', this.profile) }
        }
    },

    data() {
        return {
            mChange: { show: false, args: { id: null,
                                            level: null,
                                            id_cao_data_main: null,
                                            default_btw_value: null,
                                            editable_btw: null,
                                            filters: null
                                          },
                       result: 0,
                       return: null },

            loading_list: false,
            filters: {
                page: 1,
                per_page: 20,
                 sorting: {
                    field_name: null,
                    sort_direction: 0,
                    changed: 0,
                },
                filtering: {},
                search: "",
                status: 1,
                changed: 0,
            },
            fields: [
                { key: "name", label: this.trans('full-name',3), thStyle: { "min-width": "160px", width:"100%" }, visible: true, filtering: false },
                { key: "short_name", label: this.trans('short-name',182), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: false },                
                { key: "default_btw_value_name", label: this.trans('default-vat-value',182), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: false },
                { key: "editable_btw_name", label: this.trans('settings-btw-adjustable-in-placement',207), thStyle: { "min-width": "90px", "max-width": "90px" }, visible: true, filtering: false },
                { key: "level", label: this.trans('level',207), thStyle: { "min-width": "60px", "max-width": "60px" }, visible: true, filtering: false },                
            ],
            items: [],
        };
    },


    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("settings/sections/profile/functions/getList", {
                    filters: JSON.stringify(this.filters),
                    id_cao_data_main: this.profile.id,
                })
                .then((res) => {
                    this.cProfile.functions.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        change(item){
            this.mChange.args.level = item.level;
            this.mChange.args.id = item.id_ref_cao;
            this.mChange.args.id_cao_data_main = this.profile.id;
            this.mChange.args.default_btw_value = item.default_btw_value;
            this.mChange.args.editable_btw = item.editable_btw;
            this.mChange.args.filters = this.filters;
            this.mChange.show = true;
        },

        onCreateThis() { 

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('profile.functions.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });
        }
    },
 
};
</script>
<style>
</style>